import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import { ThemeContext } from '../utils/use-theme.ts'
import * as styles from './navigation-bar.css.ts'
import DarkMode from './svg/dark-mode.tsx'
import LightMode from './svg/light-mode.tsx'
import QuestionFillBlack from './svg/question-fill-black.tsx'
import QuestionFill from './svg/question-fill.tsx'

export function NavigationBarStub() {
  const { theme, handleToggleTheme } = useContext(ThemeContext)
  const environmentData = useEnvironmentData()

  return (
    <nav className={styles.navbar}>
      <div className={styles.leftGroup}>
        <Link to={'/'}>
          <div className={styles.navBarBrand}>
            <img src={environmentData.logoIconHref} alt={environmentData.logoIconAlt} height={32} />
            {environmentData.name === 'ntt' ? '' : ' Pixee'}
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
        <a href="https://docs.pixee.ai" target="_blank" style={{ width: '24px', height: '24px', lineHeight: '0' }}>
          {theme === 'dark' ? <QuestionFill /> : <QuestionFillBlack />}
        </a>

        <button onClick={handleToggleTheme} style={{ cursor: 'pointer' }}>
          {theme == 'light' ? <LightMode /> : <DarkMode />}
        </button>
      </div>
    </nav>
  )
}

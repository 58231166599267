import { createId } from '@paralleldrive/cuid2'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { useLocalStorage } from '@uidotdev/usehooks'
import React, { useState } from 'react'
import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { ToastVariants } from '../components/toast.css.ts'
import { ThemeContext, useTheme } from '../utils/use-theme'

export function Root() {
  const queryClient = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60_000, // 1 minute
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            throwOnError: (error: unknown) => {
              if (error instanceof Error && error.message === '499 Response') return true
              if (error instanceof Error && error.message === '302 Response') return true
              return false
            },
            retry: (failureCount, error) => {
              if (error.message === '499 Response') return false
              if (error.message === '302 Response') return false
              return failureCount < 3
            },
          },
        },
        queryCache: new QueryCache(),
        mutationCache: new MutationCache(),
      })
  )[0]

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    })
  )

  const { theme, handleToggleTheme } = useTheme()
  const [toasts, setToasts] = useState<{ id: string; message: JSX.Element; variant: ToastVariants }[]>([])
  const handleAddToastWithTimeout: HandleAddToastWithTimeout = toastProps => {
    const id = createId()
    setToasts(toasts => [...toasts, { ...toastProps, id }])
    setTimeout(() => {
      setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
    }, 5000)
  }
  const [isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb] = useLocalStorage<boolean>(
    'ui-state/isCheckInstallationsBreadcrumb',
    false
  )

  const location = useLocation()

  return (
    <ThemeContext.Provider
      value={{
        theme,
        handleToggleTheme,
      }}
    >
      <ErrorBoundary locationPathname={location.pathname}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <Outlet
            context={
              {
                isCheckInstallationsBreadcrumb,
                setIsCheckInstallationsBreadcrumb,
                toasts,
                handleAddToastWithTimeout,
              } satisfies ContextType
            }
          />
        </PersistQueryClientProvider>
      </ErrorBoundary>
    </ThemeContext.Provider>
  )
}

export type HandleAddToastWithTimeout = (toastProps: { message: JSX.Element; variant: ToastVariants }) => void

type ContextType = {
  setIsCheckInstallationsBreadcrumb: React.Dispatch<React.SetStateAction<boolean>>
  handleAddToastWithTimeout: HandleAddToastWithTimeout
  toasts: { id: string; message: JSX.Element; variant: ToastVariants }[]
  isCheckInstallationsBreadcrumb: boolean
}

export function useOutletData() {
  return useOutletContext<ContextType>()
}

class ErrorBoundary extends React.Component<{
  children: React.ReactNode
  locationPathname: string
}> {
  state = { isError: false, isAuthError: false }

  static getDerivedStateFromError(error: Error) {
    if (error.message === '499 Response') return { isAuthError: true, isError: true }
    if (error.message === '302 Response') return { isAuthError: true, isError: true }
    return { isError: true }
  }

  render() {
    if (location.pathname === '/login') return this.props.children
    if (this.state.isAuthError) return <Navigate to={`/login?redirect_path=${this.props.locationPathname}`} replace />
    if (this.state.isError) return <div>An error occurred. Please refresh the page.</div>
    return this.props.children
  }
}

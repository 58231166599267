import { useContext, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import { useSearchParams } from 'react-router-dom'
import { DefaultButton } from '../components/default-button'
import { GhostButton } from '../components/ghost-button'
import { FlexedBicepEmoji } from '../components/svg/flexed-bicep-emoji'
import { RepeatButtonEmoji } from '../components/svg/repeat-button-emoji'
import { useEnvironmentData } from '../utils/use-environment-data'
import { ThemeContext } from '../utils/use-theme'
import './login-page.css'

export const LoginPage = () => {
  const [interval, setInterval] = useState<number | null>(3000)
  const { theme } = useContext(ThemeContext)
  const environmentData = useEnvironmentData()
  const [searchParams] = useSearchParams()
  const redirectPath = searchParams.get('redirect_path')

  return (
    <div
      id={theme}
      className="flex-shrink-0 gradient"
      style={{ padding: '0 24px' }}
      onClick={() => setInterval(null)}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          setInterval(null)
        }
      }}
      role="button"
      tabIndex={0}
    >
      <Carousel pause={false} wrap={false} interval={interval} variant={theme}>
        {[
          {
            title: 'Automated PR magic',
            description: (
              <>
                {environmentData.githubAppName} quickly generates PRs for code enhancements.
                <br />
                Watch your repo improve with the click of a button.
              </>
            ),
            imgSrc: theme === 'dark' ? 'pixee-bot-pr.png' : 'pixee-bot-pr-light.png',
            imgWidth: 719,
          },
          {
            title: 'Three approaches keep you covered',
            description: `${environmentData.githubAppName} routinely monitors, responds to your activity, and fixes external findings.`,
            element: (
              <Container className="slide-2-container">
                <Row className="pb-4">
                  <Col className="d-flex justify-content-start">
                    <p className="h-6 text-start slide-text text-white mb-0">
                      <RepeatButtonEmoji /> Continuous improvement
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <p className="h-6 text-start slide-text text-white mb-0">
                      <FlexedBicepEmoji /> Pull request hardening
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-start">
                    <p className="h-6 text-start slide-text text-white mb-0">🧩️ Third-party remediation</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="paragraph slide-text text-start text-white">
                      {environmentData.githubAppName} will assess the designated default branch weekly, giving you one
                      recommendation at a time via PR. Ready for the next thing sooner? Summon with{' '}
                      <b>@pixeebot next</b> to get another recommendation.
                    </p>
                  </Col>
                  <Col>
                    <p className="paragraph slide-text text-start text-white">
                      Every time you submit code, {environmentData.githubAppName} analyzes it, identifies security
                      improvements, and immediately provides a PR to your PR. All you have to do is review and approve!
                    </p>
                  </Col>
                  <Col>
                    <p className="paragraph slide-text text-start text-white">
                      {environmentData.githubAppName} can even take on fixing the issues found by third-party code
                      scanners, just as a member of your product security team would do. Connect your tools and let{' '}
                      {environmentData.githubAppName} send you PRs to resolve the findings.
                    </p>
                  </Col>
                </Row>
              </Container>
            ),
          },
          {
            title: 'Java deserialization hardening',
            description: 'Prevent Java deserialization exploits with automated hardening.',
            imgSrc: theme === 'dark' ? 'harden-java-deserialization.png' : 'harden-java-deserialization-light.png',
            imgWidth: 1082,
          },
          {
            title: 'Upgrade unsafe TLS versions',
            description: 'Harden SSL connections by automating TLS v1.2 upgrades.',
            imgSrc: theme === 'dark' ? 'upgrade-sslcontext-tls.png' : 'upgrade-sslcontext-tls-light.png',
            imgWidth: 1082,
          },
          {
            title: 'Prevent stack trace exposure',
            description: 'Secure error responses by eliminating stack trace exposure.',
            imgSrc: theme === 'dark' ? 'stack-trace-exposure.png' : 'stack-trace-exposure-light.png',
            imgWidth: 1082,
          },
          {
            title: 'Fix database resource leaks',
            description: 'Effortlessly fix potential database leaks for peak performance and security.',
            imgSrc: theme === 'dark' ? 'database-resource-leak.png' : 'database-resource-leak-light.png',
            imgWidth: 877,
          },
          {
            title: 'Protect against zip slip',
            description: 'Stop zip slip exploits with automated hardening.',
            imgSrc: theme === 'dark' ? 'harden-zip-entry-paths.png' : 'harden-zip-entry-paths-light.png',
            imgWidth: 1082,
          },
        ].map((item, index) => (
          <Carousel.Item key={index}>
            <div className="text-center carousel-item-wrapper">
              <p className="carousel-item-title slide-text">{item.title}</p>
              <p className="carousel-item-description slide-text">{item.description}</p>
              {item.imgSrc ? (
                <Image
                  src={item.imgSrc}
                  width={item.imgWidth}
                  fluid={true}
                  style={{ borderRadius: index === 1 ? undefined : '15px' }}
                  className={index === 1 ? undefined : 'shadow'}
                />
              ) : item.element ? (
                item.element
              ) : null}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="install-button-group">
        <p className="ai-features-enabled">
          ✨ AI features enabled by default.{' '}
          <a href="https://docs.pixee.ai/faqs/" target="_blank" rel="noopener noreferrer">
            Learn more.
          </a>
        </p>
        <div className="install-button">
          <div className="col d-flex justify-content-center">
            <DefaultButton href={`https://github.com/apps/pixeebot/installations/select_target`}>Install</DefaultButton>
          </div>
        </div>
        <div className="existing-users">
          <p className="install-pixee-text">Already installed?</p>
          <div className="col d-flex justify-content-center">
            <GhostButton href={redirectPath ? `/api/auth/login?redirect_path=${redirectPath}` : '/api/auth/login'}>
              Sign In
            </GhostButton>
          </div>
        </div>
      </div>
    </div>
  )
}

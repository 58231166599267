import { PropsWithChildren } from 'react'
import { Footer } from '../components/footer'
import { NavigationBar } from '../components/navigation-bar'
import { NavigationBarStub } from '../components/navigation-bar-stub'
import { useOutletData } from '../routes/root'
import * as styles from './withLayout.css'

export const WithLayout = (props: PropsWithChildren) => {
  const { isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb, toasts, handleAddToastWithTimeout } =
    useOutletData()

  return (
    <>
      <NavigationBar
        isCheckInstallationsBreadcrumb={isCheckInstallationsBreadcrumb}
        setIsCheckInstallationsBreadcrumb={setIsCheckInstallationsBreadcrumb}
        toasts={toasts}
        handleAddToastWithTimeout={handleAddToastWithTimeout}
      />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

export const WithStubLayout = (props: PropsWithChildren) => {
  return (
    <>
      <NavigationBarStub />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

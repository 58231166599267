const QuestionFillBlack = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_72_227)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.81737 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.81737 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.81737 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.81737 24 12ZM8.244 9.0495C8.19605 9.05015 8.14967 9.04093 8.107 9.02242C8.06434 9.00389 8.02632 8.97644 7.99422 8.94176C7.96212 8.90708 7.93625 8.8659 7.91902 8.82072C7.9018 8.77554 7.89396 8.72731 7.896 8.67899C8.25 6.13649 10.1055 5.25 12.0074 5.25C14.1014 5.25 16.0154 6.345 16.0154 8.61C16.0154 10.23 15.063 11.0009 14.1494 11.6954C13.044 12.534 12.6344 12.8474 12.6344 13.9244V14.082C12.6344 14.1815 12.595 14.2768 12.5246 14.3472C12.4542 14.4176 12.359 14.457 12.2594 14.457H11.0445C10.9461 14.457 10.8516 14.4182 10.7814 14.3492C10.7112 14.2802 10.6711 14.1864 10.6695 14.088L10.6635 13.7624C10.6065 12.372 11.406 11.5154 12.4154 10.8179C13.3004 10.116 13.863 9.678 13.863 8.7255C13.863 7.488 12.921 6.9735 11.892 6.9735C10.6875 6.9735 10.0125 7.6905 9.879 8.6745C9.852 8.88 9.687 9.0495 9.48 9.0495H8.2455H8.244ZM11.7315 18.714C10.8555 18.714 10.218 18.123 10.218 17.3234C10.218 16.4954 10.8555 15.9134 11.733 15.9134C12.6464 15.9134 13.275 16.4954 13.275 17.3234C13.275 18.123 12.645 18.714 11.7315 18.714Z"
          fill="#2C2541"
        />
      </g>
      <defs>
        <clipPath id="clip0_72_227">
          <rect width="24" height="24" fill="#2C2541" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QuestionFillBlack

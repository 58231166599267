import * as Sentry from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { FetchResponse } from 'openapi-fetch'
import { MediaType } from 'openapi-typescript-helpers'
import { match } from 'ts-pattern'
import { z } from 'zod'
import codemods from './codemods.json' assert { type: 'json' }
import { client } from './user-platform-api'
import {
  AnalysisStatusLogsByRepositoriesId,
  Changesets,
  CodemodCatalog,
  CodemodHitStatusesByRepositoriesId,
  CodemodHitStatusesByRepositoriesIdLenient,
  CodemodPreference,
  CodemodWithDescription,
  Codemods,
  Installations,
  OwnerPreference,
  OwnerPreferences,
  PullRequests,
  RepositoriesWithInstallationId,
  RepositoryActivation,
  RepositoryActivations,
  User,
} from './user-platform-api-schemas'
import { paths } from './user-platform-api.d'

type ShapesMatch<T, U> = [T] extends [U] ? ([U] extends [T] ? true : false) : false
type TypesMatch<T, U> =
  ShapesMatch<T, U> extends true ? (ShapesMatch<keyof T, keyof U> extends true ? true : false) : false
type Expect<T extends true> = T
type Response<T> = T extends { get: { responses: { 200: { content: { 'application/json': infer R } } } } } ? R : never

export type TestUser = Expect<TypesMatch<User, Response<paths['/api/user']>>>
export const useGetUser = ({ enabled = true }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await handleRequest(() => client.GET('/api/user'))
      return User.parse(data)
    },
    staleTime: 300_000, // 5 min
    enabled,
  })

export type TestInstallations = Expect<TypesMatch<Installations, Response<paths['/api/user/installations']>>>
export const useGetInstallations = () => {
  let { data, ...rest } = useQuery({
    queryKey: ['installations'],
    queryFn: async () => {
      const { data } = await handleRequest(() => client.GET('/api/user/installations'))
      return Installations.parse(data)
    },
    staleTime: 120_000, // 2 min
  })
  data = data ?? []
  return { data, ...rest }
}

export type TestRepositories = Expect<
  TypesMatch<RepositoriesWithInstallationId, Response<paths['/api/user/repositories']>>
>
export const useGetRepositories = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['repositories'],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/user/repositories', {
          params: { query: { installation_id: installationIds } },
        })
      )
      return RepositoriesWithInstallationId.parse(data)
    },
    staleTime: 120_000, // 2 min
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export type TestPullRequest = Expect<TypesMatch<PullRequests, Response<paths['/api/user/pull-requests']>>>
export const useGetPixeebotPullRequests = (installationOwnerLogins: string[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['pull-requests', installationOwnerLogins],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/user/pull-requests', {
          params: { query: { account_logins: installationOwnerLogins } },
        })
      )
      return PullRequests.parse(data)
    },
    enabled: installationOwnerLogins.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export type TestCodemodHitStatusesByRepositoriesId = Expect<
  TypesMatch<CodemodHitStatusesByRepositoriesId, Response<paths['/api/user/repositories/codemods']>>
>
export const useGetRepositoriesCodemodHitStatuses = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['codemod-hit-status'],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/user/repositories/codemods', {
          params: { query: { installation_id: installationIds } },
        })
      )
      return CodemodHitStatusesByRepositoriesIdLenient.parse(data)
    },
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export type TestAnalysisStatusLogsByRepositoriesId = Expect<
  TypesMatch<AnalysisStatusLogsByRepositoriesId, Response<paths['/api/user/repositories/analyses']>>
>
export const useGetRepositoriesAnalysisStatusLogs = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['analysis-status-logs', installationIds],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/user/repositories/analyses', {
          params: { query: { installation_id: installationIds } },
        })
      )
      return AnalysisStatusLogsByRepositoriesId.parse(data)
    },
    staleTime: 20_000, // 20 seconds
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export const usePostAnalysisMutation = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: async ({
      installationId,
      repositoryId,
      codemodId,
    }: {
      installationId: number
      repositoryId: number
      codemodId: string
    }) => {
      const { response, data, error } = await client.POST('/api/user/{installation_id}/{repository_id}/analyses', {
        params: {
          path: {
            installation_id: installationId,
            repository_id: repositoryId,
          },
        },
        body: {
          codemod_id: codemodId,
        },
      })

      if (response.status === 400 || response.status === 403 || response.status === 409) {
        throw new Error(error?.message || `API request failed with status ${response.status}`)
      }

      if (response.status !== 200) {
        throw new Error(`API request failed with status ${response.status}`)
      }

      return z.object({ analysis_id: z.string() }).parse(data).analysis_id
    },
    onSuccess,
    onError,
  })
}

export type TestOwnerPreferences = Expect<TypesMatch<OwnerPreferences, Response<paths['/api/owners/preferences']>>>
export const useGetOwnerPreferences = (ownerIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['owner-preferences', ownerIds],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/owners/preferences', { params: { query: { owner_id: ownerIds } } })
      )
      return OwnerPreferences.parse(data).map(ownerPreference => ({
        ownerId: ownerPreference.owner_id,
        codemodPreference: ownerPreferenceToCodemodPreference(ownerPreference),
      }))
    },
    enabled: ownerIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export const usePutOwnerPreferenceMutation = ({ onSuccess, onError }: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: ({ ownerId, codemodPreference }: { ownerId: number; codemodPreference: CodemodPreference }) => {
      return client.PUT('/api/owners/{owner_id}/preferences', {
        params: { path: { owner_id: ownerId } },
        body: {
          tier: 'DEFAULT',
          codemod_catalog: codemodPreferenceToCodemodCatalog(codemodPreference),
        },
      })
    },
    onSuccess,
    onError,
  })
}

export type TestRepositoryActivations = Expect<
  TypesMatch<RepositoryActivations, Response<paths['/api/user/repositories/activations']>>
>
export const useGetRepositoriesActivations = (installationIds: number[]) => {
  let { data, ...rest } = useQuery({
    queryKey: ['repositories-activations', installationIds],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/user/repositories/activations', {
          params: { query: { installation_id: installationIds } },
        })
      )
      return RepositoryActivations.parse(data)
    },
    enabled: installationIds.length > 0,
  })

  data = data ?? []
  return { data, ...rest }
}

export const usePutRepositoriesActivationsMutation = ({
  onSuccess,
  onError,
}: { onSuccess?: any; onError?: any } = {}) => {
  return useMutation({
    mutationFn: ({
      installationIds,
      repositoryActivations,
    }: {
      installationIds: number[]
      repositoryActivations: RepositoryActivation[]
    }) => {
      return client.PUT('/api/user/repositories/activations', {
        params: { query: { installation_id: installationIds } },
        body: repositoryActivations,
      })
    },
    onSuccess,
    onError,
  })
}

export const useGetCodemods = () => {
  return useQuery({
    queryKey: ['codemods'],
    queryFn: async () => {
      return Codemods.parse(codemods)
    },
  })
}

export const useGetCodemod = (codemodId: string) => {
  return useQuery({
    queryKey: ['codemod', codemodId],
    queryFn: async () => {
      const codemod = codemods[codemodId]
      if (!codemod) {
        throw new Error(`Codemod with id ${codemodId} not found`)
      }
      return CodemodWithDescription.parse(codemod)
    },
  })
}

export const useGetChangesets = ({
  installationId,
  repositoryId,
  analysisId,
  codemodId,
}: {
  installationId: string
  repositoryId: string
  analysisId: string
  codemodId: string
}) => {
  let { data, ...rest } = useQuery({
    queryKey: ['changeset', installationId, repositoryId, analysisId, codemodId],
    queryFn: async () => {
      const { data } = await handleRequest(() =>
        client.GET('/api/changesets/{installation_id}/{repository_id}/{analysis_id}/{codemod_id}', {
          params: {
            path: {
              installation_id: Number(installationId),
              repository_id: Number(repositoryId),
              analysis_id: analysisId,
              codemod_id: codemodId,
            },
          },
        })
      )
      return Changesets.parse(data)
    },
  })
  data = data ?? []
  return { data, ...rest }
}

const ownerPreferenceToCodemodPreference = ({ codemod_catalog }: OwnerPreference): CodemodPreference => {
  return match(codemod_catalog)
    .with('MORE', () => 'everything')
    .with('MODERATE', () => 'security+tools')
    .with('DEFAULT', () => 'security+tools')
    .with('LESS', () => 'tools-only')
    .with('CUSTOM', () => 'custom')
    .exhaustive() as CodemodPreference
}

const codemodPreferenceToCodemodCatalog = (codemodPreference: CodemodPreference) => {
  return match(codemodPreference)
    .with('everything', () => 'MORE')
    .with('security+tools', () => 'MODERATE')
    .with('tools-only', () => 'LESS')
    .with('custom', () => 'CUSTOM')
    .exhaustive() as CodemodCatalog
}

const handleRequest = async <T, Options, Media extends MediaType>(
  requestFunction: () => Promise<FetchResponse<T, Options, Media>>
): Promise<FetchResponse<T, Options, Media>> => {
  try {
    const result = await requestFunction()
    if (result.response.status === 499) throw new Error('499 Response')
    if (result.response.type === 'opaqueredirect' || result.response.status === 0) throw new Error('302 Response')
    if (result.error) throw new Error(result.error.message || 'Unknown error')
    return result
  } catch (error) {
    // Ignore 499 errors because they are expected
    // 302 errors are unexpected are handled like 499
    if (error instanceof Error && error.message !== '499 Response') Sentry.captureException(error)
    throw error
  }
}

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { typography } from '../main.css.ts'
import { useEnvironmentData } from '../utils/use-environment-data.ts'
import { ThemeContext } from '../utils/use-theme'
import { useGetUser } from '../utils/user-platform-api-hooks'
import { CodemodPreferenceConfigurationDialog } from './codemod-preference-configuration-dialog'
import * as styles from './navigation-bar.css.ts'
import DarkMode from './svg/dark-mode'
import LightMode from './svg/light-mode'
import QuestionFill from './svg/question-fill'
import QuestionFillBlack from './svg/question-fill-black'
import { Toast } from './toast'
import { ToastContainer } from './toast-container'
import { ToastVariants } from './toast.css.ts'

export function NavigationBar({
  isCheckInstallationsBreadcrumb,
  setIsCheckInstallationsBreadcrumb,
  toasts,
  handleAddToastWithTimeout,
}: Readonly<{
  isCheckInstallationsBreadcrumb: boolean
  setIsCheckInstallationsBreadcrumb: React.Dispatch<React.SetStateAction<boolean>>
  toasts: ReadonlyArray<{ readonly id: string; readonly message: JSX.Element; readonly variant: ToastVariants }>
  handleAddToastWithTimeout: (toastProps: Readonly<{ message: JSX.Element; variant: ToastVariants }>) => void
}>) {
  const { theme, handleToggleTheme } = useContext(ThemeContext)
  const [isCodemodPreferenceConfigurationEnabled, setIsCodemodPreferenceConfigurationEnabled] = useState<boolean>(false)
  const { data: user } = useGetUser()
  const environmentData = useEnvironmentData()

  return (
    <>
      <ToastContainer>
        {toasts.map(toast => (
          <Toast key={toast.id} variant={toast.variant}>
            {toast.message}
          </Toast>
        ))}
      </ToastContainer>
      <nav className={styles.navbar}>
        <div className={styles.leftGroup}>
          <Link to="/">
            <div className={styles.navBarBrand}>
              <img src={environmentData.logoIconHref} alt={environmentData.logoIconAlt} height={32} />
              {environmentData.name === 'ntt' ? '' : ' Pixee'}
            </div>
          </Link>
          {user && (
            <Link
              to="/installations"
              className={styles.navLink}
              onClick={() => setIsCheckInstallationsBreadcrumb(false)}
            >
              Installations {isCheckInstallationsBreadcrumb && <span className={styles.breadcrumb} />}
            </Link>
          )}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
          <a href="https://docs.pixee.ai" target="_blank" style={{ width: '24px', height: '24px', lineHeight: '0' }}>
            {theme === 'dark' ? <QuestionFill /> : <QuestionFillBlack />}
          </a>

          <button onClick={handleToggleTheme} style={{ cursor: 'pointer' }}>
            {theme == 'light' ? <LightMode /> : <DarkMode />}
          </button>

          {user && (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className={styles.dropdownMenuTrigger}>
                <img
                  src={user.avatar_url}
                  style={{ borderRadius: '50%', marginRight: '8px' }}
                  width={28}
                  height={28}
                  alt={`${user.login}'s user profile image`}
                />
                <TriangleUpIcon className={styles.triangleUpIcon} />
                <TriangleDownIcon className={styles.triangleDownIcon} />
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content className={styles.dropdownMenuContent} sideOffset={17}>
                  <DropdownMenu.Label>
                    <p className={typography.paragraph} style={{ margin: 0 }}>
                      Signed in as{' '}
                    </p>
                    <p className={typography.h6} style={{ margin: 0 }}>
                      {user.login.length > 20 ? `${user.login.slice(0, 20 - 3)}...` : user.login}
                    </p>
                  </DropdownMenu.Label>
                  <DropdownMenu.Separator className={styles.dropdownMenuSeparator} />
                  <DropdownMenu.Item
                    className={styles.dropdownMenuItem}
                    onClick={() => {
                      setIsCodemodPreferenceConfigurationEnabled(true)
                    }}
                  >
                    View / change default preference
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator className={styles.dropdownMenuSeparator} />
                  <DropdownMenu.Item className={styles.dropdownMenuItem} asChild>
                    <a href="/api/auth/logout" style={{ color: 'unset' }}>
                      Sign Out
                    </a>
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          )}
          {user && isCodemodPreferenceConfigurationEnabled && (
            <CodemodPreferenceConfigurationDialog
              handleCloseConfigurationDialog={() => setIsCodemodPreferenceConfigurationEnabled(false)}
              handleAddToastWithTimeout={handleAddToastWithTimeout}
            />
          )}
        </div>
      </nav>
    </>
  )
}
